import logo from './logo.svg';
import './App.css';
import { router } from './router';
import { RouterProvider } from 'react-router-dom';
import './utils/web3Modal';
import { Box } from '@mui/material';
import { useHeight } from './useHeight';

function App() {
  const {isSmallHeight}=useHeight()
  return (
    <Box display={'flex'}  justifyContent={'center'} bgcolor={'#2D2F4B'} height={'100vh'} alignItems={'center'}>
      <Box width={'375px'} maxWidth={'100%'} maxHeight={'100%'} height={'750px'} bgcolor={'#1F2034'} borderRadius={'8px'} border={'0.5px solid #49527D'}>
        <RouterProvider router={router} />
      </Box>
    </Box>
  );
}

export default App;
