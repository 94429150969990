import { Box, Typography } from "@mui/material"
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react"
// import {us} from "@web3modal/ethers5"
import { green, textNormal } from "../color";
import { addressShort } from "../utils/address";
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHeight } from "../useHeight";
const Address = ({ address, onClick, sx, ...rest }) => {
  return <Box sx={{ cursor: 'pointer', ...sx }} display={'flex'} alignItems={'center'} onClick={onClick} {...rest}>
    <CircleIcon style={{ width: '0.3em', height: '0.3em', color: green }} /><Typography color={textNormal} marginLeft={0.5} fontSize={'12px'} component={'span'}>{addressShort(address)}</ Typography>
  </Box>
}

export default (props) => {
  const { open } = useWeb3Modal()
  const { address, isConnected } = useWeb3ModalAccount()
  const {isSmallHeight} = useHeight()
  return <Box width={'100%'} color={'white'} display={'flex'} justifyContent={'space-between'} paddingTop={isSmallHeight?6:7} {...props}>
    <Box visibility={props.hideBack ? 'hidden' : 'visible'} component={'div'} onClick={props.onClickBack} sx={{ cursor: 'pointer' }}>
      <ArrowBackIcon />
    </Box>
    {
      isConnected ? <Address onClick={() => open()} address={address} /> : <></>
    }
  </Box>
}