import { createBrowserRouter } from "react-router-dom";
import Home from "./routes/Home";
import Claim from "./routes/Claim";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/claim",
    element: <Claim />,
  },
]);