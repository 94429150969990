import { providers } from "ethers";
import { arrayify, isAddress, sha256, toUtf8Bytes } from "ethers/lib/utils";

const baseURL = process.env.REACT_APP_BASE_URL;
const isTestNet = process.env.REACT_APP_TESTNET === 'true';
const taikoRPCProvider = new providers.JsonRpcProvider(process.env.REACT_APP_TAIKO_RPC_URL) ;
const ethreumRPCProvider = new providers.JsonRpcProvider(process.env.REACT_APP_ETHREUM_RPC_URL);
export const check = async (address) => {

  const isInCounterFactualStatus = await fetch(
    `${baseURL}/api/wallet/v3/wallet/type?wallet=${address}&network=${isTestNet ? 'SEPOLIA' : 'ETHEREUM'}`
  ).then(res => res.json())
  .then(res => {
    return res.data.isInCounterFactualStatus
  })
  return fetch(`${baseURL}/api/v3/datacenter/airdrop/query?address=${address}`)
    .then((res) => res.json())
    .then((res) => {
      if (res?.resultInfo?.code === 100000) {
        throw res.resultInfo
      }
      return {
        isClaimable: res.amount !== "0",
        isClaimed: res.claimed === 2,
        addressBound: res.claimed === 1 || res.claimed === 2,
        amount: res.amount,
        isLoopringWallet: res.loopring_wallet,
        isInCounterFactualStatus
      };
    });
};
const sortObject = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});
const serializeDataIfNeeded = function (value) {
  const nonString = typeof value !== "string";

  return nonString
    ? JSON.stringify(value !== undefined ? sortObject(value) : {})
    : value || "";
};
export const claim = async (myAddress, bindAddress, provider) => {
    const params = {
      address: myAddress,
      bindAddress
    }
    const parameterString = serializeDataIfNeeded(params)
    const signer = new providers.Web3Provider(provider).getSigner()
    const msg = `You are binding below address to receive your TAIKO airdrop: ${bindAddress.toLowerCase()}`
    const sig = await signer.signMessage(msg)
    return fetch(`${baseURL}/api/v3/datacenter/airdrop/claim`, {
      method: "POST",
      body: parameterString,
      headers: {
        'Content-Type': 'application/json',
        "X-API-SIG": sig,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res.isSuccess;
      })
};

export const isValidateBindingAddress = async (address) => {

  const network = "TAIKO"; 
  const isValidAddress = isAddress(address);
  if (!isValidAddress) {
    return {
      isTaikoSmartWallet: false,
      isEOA: false,
      isValidAddress: false,
    };
  }

  const [taikoContractCode, ethreumContractCode, {isTaikoSmartWallet}, isETHEREUMSmartWallet] = await Promise.all([
    taikoRPCProvider.getCode(address),
    ethreumRPCProvider.getCode(address),
    fetch(
      `${baseURL}/api/wallet/v3/wallet/type?wallet=${address}&network=${network}`
    )
      .then((res) => res.json())
      .then((res) => {
        return {
          isTaikoSmartWallet:
            res.data.isInCounterFactualStatus ||
            (res.data.loopringWalletContractVersion && res.data.loopringWalletContractVersion !== ''),
        };
      }),
    fetch(
      `${baseURL}/api/wallet/v3/wallet/type?wallet=${address}&network=${isTestNet ? 'SEPOLIA' : 'ETHEREUM'}`
    )
      .then((res) => res.json())
      .then((res) => {
        return res.data.isInCounterFactualStatus ||
          (res.data.loopringWalletContractVersion && res.data.loopringWalletContractVersion !== '')
      })
  ])

  return {
    isTaikoSmartWallet: isTaikoSmartWallet,
    isEOA: isValidAddress && taikoContractCode === '0x' && ethreumContractCode === '0x' && !isETHEREUMSmartWallet,
    isValidAddress,
  };
};
