import { Box, BoxProps } from '@mui/material'
import { STATIC_RESOURCE } from '../constants'
// import { STATIC_RESOURCE } from '../constants'

export default (props) => {
  const { size = 40, withContainer, ...rest } = props
  const loading = (
    <Box
      component={'img'}
      src={`${STATIC_RESOURCE}/images/loading-line.gif`}
      width={size}
      height={size}
      {...rest}
    />
  )
  return withContainer ? (
    <Box
      height={'100%'}
      width={'100%'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {loading}
    </Box>
  ) : (
    loading
  )
}
