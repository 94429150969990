import { Button } from "@mui/material"
import { blue, buttonDisabled, buttonTextDisabled, textNormal } from "../color"

export default (props) => {
  const { sx, ...rest } = props

  return <Button variant="contained" sx={{
    height: '48px',
    boxShadow: 'none',
    borderRadius: '8px',
    color: props.disabled ? buttonTextDisabled : textNormal,
    background: props.disabled ? buttonDisabled : blue ,
    fontSize: '15px',
    fontWeight: '600',
    textTransform: 'none',
    ':hover': {
      background: props.disabled ? buttonDisabled : blue,
      boxShadow: 'none',
    },
    ...sx
  }} {...rest} disabled={false}/>
}