import { Box, Input, Link, Typography } from "@mui/material"
import Button1 from "../components/Button1"
import LoadingComponent from "../components/Loading"
import { useEffect, useState } from "react"
import { blue, green, orange, red, textLight, textNormal } from "../color"
import { STATIC_RESOURCE } from "../constants"
import Header from "../components/Header"
import { useLocation, useNavigate } from "react-router-dom"
import { claim, isValidateBindingAddress } from "../utils/api"
import CircularProgress from '@mui/material/CircularProgress';
import { useHeight } from "../useHeight"
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react"
import { providers } from "ethers"


const Init = ({ inputChecking, inputHint, claimButtonDisabled, onClickBack, onClickConfirm, address, onChangeAddress }) => {
  const { isSmallHeight } = useHeight()
  return <Box paddingX={4}  position={'relative'} height={'100%'} display={'flex'} flexDirection={'column'} >
    <Header onClickBack={onClickBack} />
    <Typography marginTop={isSmallHeight ? 5 : 8} fontSize={'22px'} color={textNormal}>Bind Qualified Wallet</Typography>
    <Typography marginTop={1} fontSize={isSmallHeight ? '12px' : '14px'} color={textLight}>To receive this airdrop, kindly bind a wallet address that works on the Taiko Mainnet. It can be an EOA wallet you own, or a Loopring Smart Wallet created on the Taiko network. You can update your Loopring Smart Wallet app to the latest version to deploy a Taiko smart wallet for free.</Typography>
    <Typography marginTop={1} fontSize={isSmallHeight ? '12px' : '14px'} color={textLight}>The airdrop will be distributed daily by Loopring. The earlier you bind your wallet, the sooner you will receive the airdrop.</Typography>

    <Input disableUnderline sx={{
      background: '#3C4277',
      marginTop: 5.5,
      paddingX: 2,
      paddingY: 1,
      borderRadius: '8px',
      input: { color: textNormal, fontSize: '15px' }
    }} color={textNormal} fullWidth onInput={onChangeAddress} value={address} placeholder="Input Taiko Wallet Address" />
    <Typography marginTop={0.5} fontSize={isSmallHeight ? '12px' : '14px'} sx={{ visibility: inputHint ? 'visible' : 'hidden' }} color={red}>{inputHint ?? '-'}</Typography>
    {/* <Box display={'flex'} justifyContent={'center'}>
      <Link fontSize={'13px'} color={blue} sx={{ cursor: 'pointer' }} marginTop={isSmallHeight ? 10 : 13} target="_blank" href={'https://docs-wallet.loopring.io/additional-features/taiko-testnet'} >How to create a Taiko Wallet ?</Link>
    </Box> */}

    <Button1 disabled={claimButtonDisabled} sx={{
      position: 'absolute',
      bottom: 48,
      left: 32 ,
      right: 32,

    }}  onClick={() => {
      !claimButtonDisabled && onClickConfirm()
    }}>{inputChecking ? <CircularProgress size={20} /> : 'Confirm'}</Button1>
  </Box>
}
const Loading = () => {
  return <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
    <LoadingComponent />
  </Box>
}

const Success = ({ onClickBack, onClickDone }) => {
  return <Box paddingX={4} position={'relative'} height={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <Header onClickBack={onClickBack} />
    <Box marginTop={20} width={48} height={48} src={`${STATIC_RESOURCE}/air/success.png`} component={'img'} />
    <Typography marginTop={4} fontSize={'19px'} color={green}>Submit Successful! </Typography>
    <Typography textAlign={'center'} marginTop={1.5} fontSize={'13px'} color={textLight}>Loopring will send airdrop to binding address later</Typography>
    <Button1 sx={{
      position: 'absolute',
      bottom: 48,
      left: 32 ,
      right: 32,

    }}  onClick={onClickDone} >Done</Button1>
  </Box>
}
const Failed = ({ onClickBack, onClickDone }) => {
  const { isSmallHeight } = useHeight()
  return <Box position={'relative'} height={'100%'}  paddingX={4} display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <Header onClickBack={onClickBack} />
    <Box marginTop={20} width={48} height={48} src={`${STATIC_RESOURCE}/air/failed.png`} component={'img'} />
    <Typography marginTop={4} fontSize={'22px'} color={red}>Failed</Typography>
    <Typography textAlign={'center'} marginTop={1.5} fontSize={'15px'} color={textLight}>There is some issue when accessing via WalletConnect. Please try to access it from Loopring Smart Wallet directly via below procedures: Navigate to Utility -> DApp Browser, and access the "TAIKO Airdrop Claim" entry to check your eligibility.</Typography>
    <Button1 sx={{
      position: 'absolute',
      bottom: 48,
      left: 32 ,
      right: 32,

    }}  onClick={onClickDone} >Done</Button1>
  </Box>
}
export default () => {
  const { state: addressCheckResult } = useLocation()
  const {
    isLoopringWallet,
    isInCounterFactualStatus
  } = addressCheckResult
  const {address: myAddress, isConnected} = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [addressValidationResult, setAddressValidationResult] = useState({
    isTaikoSmartWallet: false,
    isValidAddress: false,
    isEOA: false,
    isChecking: false
  })

  const checkAddress = (inputAddress) => {
    setAddressValidationResult({
      isTaikoSmartWallet: undefined,
      isEOA: undefined,
      isValidAddress: undefined,
      isChecking: true
    })
    isValidateBindingAddress(inputAddress).then(x => {
      setAddressValidationResult({
        isTaikoSmartWallet: x.isTaikoSmartWallet,
        isEOA: x.isEOA,
        isValidAddress: x.isValidAddress,
        isChecking: false
      })
    }).catch(() => {
      setAddressValidationResult({
        isTaikoSmartWallet: undefined,
        isEOA: undefined,
        isValidAddress: undefined,
        isChecking: false
      })
    })
  }
  const [address, setAddress] = useState('')
  useEffect(() => {
    if (!(isInCounterFactualStatus || isLoopringWallet) && myAddress) {
      setAddress(myAddress)
      checkAddress(myAddress)
    }
  }, [myAddress])
  const nav = useNavigate()
  useEffect(() => {
    if (!isConnected) {
      nav('/')
    }
  }, [isConnected])
  const [status, setStatus] = useState('init')
  
  const claimButtonDisabled = !address || addressValidationResult.isChecking
    || (!addressValidationResult.isTaikoSmartWallet && !addressValidationResult.isEOA)

  const inputHint = (address && !addressValidationResult.isChecking)
    ? !addressValidationResult.isValidAddress
      ? 'invalid address'
      : (!addressValidationResult.isTaikoSmartWallet && !addressValidationResult.isEOA)
        ? 'The wallet address is neither a Loopring Smart Wallet created on the Taiko mainnet, nor an EOA wallet. Please supply another qualified wallet address to proceed.'
        : undefined
    : undefined

  return <Box height={'100%'}>
    {status === 'init' ? <Init
      inputHint={inputHint}
      inputChecking={addressValidationResult.isChecking}
      claimButtonDisabled={claimButtonDisabled}
      onClickConfirm={() => {
        
        setStatus('loading')
        claim(myAddress, address, walletProvider).then(isSuccess => {
          if (isSuccess) {
            setStatus('success')
          } else {
            setStatus('failed')
          }
        }).catch(e => {
          setStatus('init')
        })
      }}
      onClickBack={() => {
        nav('/')
      }}
      address={address}
      onChangeAddress={e => {
        setAddress(e.target.value)
        if (e.target.value) {
          checkAddress(e.target.value)
        }
      }}
    /> : status === 'loading' ? <Loading

    /> : status === 'success' ? <Success
      onClickBack={() => {
        nav('/')
      }}
      onClickDone={() => {
        nav('/')
      }}

    /> : status === 'failed' ? <Failed
      onClickBack={() => {
        nav('/')
      }}
      onClickDone={() => {
        nav('/')
      }}
    /> : <>error</>}
  </Box>
}