import { useState } from "react";

export const useHeight = () => {
  const [isSmallHeight, setIsSmallHeight] = useState(window.innerHeight < 750);
  const [isUltraSmallHeight, setIsUltraSmallHeight] = useState(window.innerHeight < 670);

  window.addEventListener("resize", () => {
    setIsSmallHeight(window.innerHeight < 750);
    setIsUltraSmallHeight(window.innerHeight < 670);
  });

  return {
    isSmallHeight,
    isUltraSmallHeight,
  };
};
