import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

export const web3Modal = createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata: {
      name: "loopring taiko airdrop",
      description: "loopring taiko airdrop",
      url: "https://claimtaiko.loopring.io",
      icons: ["https://static.loopring.io/assets/svg/logo.svg"],
    },
  }),
  chains: [
    process.env.REACT_APP_TESTNET === 'true' ? {
      chainId: 11155111,
      name: "Sepolia",
      currency: "ETH",
      explorerUrl: "https://sepolia.etherscan.io",
      rpcUrl: "https://sepolia.infura.io/v3/b951a403f3d3426fb2008c6923254dbc",
    } : {
      chainId: 1,
      name: "Ethereum",
      currency: "ETH",
      explorerUrl: "https://etherscan.io",
      rpcUrl: "https://mainneteth.loopring.io",
    },
  ],
  projectId: 'c477c5fe7e8486177eb21cc6cce4677f',
  enableAnalytics: true, // Optional - defaults to your Cloud configuration,
  featuredWalletIds: [],
});

