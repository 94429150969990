import { Box,Link,Button, IconButton, Typography, styled, useTheme } from "@mui/material"
import Button1 from "../components/Button1"
import { useWeb3Modal, useWalletInfo, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react"
// import {us} from "@web3modal/ethers5"
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Loading from "../components/Loading";
import { blue, green, orange, red, taikoRed, textLight, textNormal } from "../color";
import { EXPIRE_TIMESTAMP, STATIC_RESOURCE } from "../constants";
import { addressShort } from "../utils/address";
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from "react";
import { check } from "../utils/api";
import { X } from "@mui/icons-material";
import { numberFormat } from "../utils/numberFormat";
import {  useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useHeight } from "../useHeight";
import moment from "moment";

const MeetRequirement = ({ isMeet, text, ...rest }) => {
  return <Typography display={'flex'} fontSize={'13px'} color={isMeet ? green : red} {...rest}>
    {isMeet ? <DoneSharpIcon /> : <CloseSharpIcon />} <Typography>{text}</Typography>
  </Typography>
}

// const Address = ({ address, onClick, sx, ...rest }) => {
//   return <Box sx={{ cursor: 'pointer', ...sx }} display={'flex'} alignItems={'center'} onClick={onClick} {...rest}>
//     <CircleIcon style={{ width: '0.3em', height: '0.3em', color: green }} /><Typography color={textNormal} marginLeft={0.5} fontSize={'12px'} component={'span'}>{addressShort(address)}</ Typography>
//   </Box>
// }
// export const Header = (props) => {
//   const { open } = useWeb3Modal()
//   const { address, isConnected } = useWeb3ModalAccount()
//   console.log('hideBack', props)
//   return <Box width={'100%'} color={'white'} display={'flex'} justifyContent={'space-between'} {...props}>
//     <Box visibility={props.hideBack ? 'hidden' : 'visible'} component={'div'} onClick={props.onClickBack} sx={{ cursor: 'pointer' }}>
//       <ArrowBackIcon />
//     </Box>
//     {
//       isConnected ? <Address onClick={() => open()} address={address} /> : <></>
//     }
//   </Box>
// }

const UnConnected = ({ onClickConnect }) => {
  const {isSmallHeight, isUltraSmallHeight} = useHeight()
  return <Box position={'relative'} height={'100%'} paddingX={4} display={'flex'}  flexDirection={'column'} alignItems={'center'}>
    <Box sx={{
      marginLeft: 6,
      marginTop: isSmallHeight ? 7 : 9,
      height: isUltraSmallHeight ? 160 : isSmallHeight ? 200 : 260,
      width: isUltraSmallHeight ? 206 : isSmallHeight ? 258 : 335
    }} component={'img'} src={`${STATIC_RESOURCE}/air/drop.png`} height={260} width={335} />
    <Typography sx={{ marginTop: 4.5 }} color={textNormal} fontSize={isUltraSmallHeight ? '22px' : '28px'} textAlign={'center'}>Check your eligibility <br /> to claim <Typography fontSize={'28px'} component={'span'} color={taikoRed}>Taiko</Typography></Typography>
    <Typography color={textLight} marginTop={1} fontSize={'11px'}>The claim is only eligible between June 4, 2024, and July 4, 2024 (GMT+8). Please note that if you have not confirmed the binding address by the deadline, you will no longer be able to claim the airdrop.</Typography>
    <Button1 fullWidth sx={{ marginTop: isUltraSmallHeight ? 2 : 5 }} onClick={() => {
      onClickConnect()
    }}>Connect Wallet</Button1>
    <Typography position={'absolute'} textAlign={'center'} bottom={isUltraSmallHeight ? 32 : 48} left={32} right={32} color={textLight} fontSize={'11px'}>Please connect the wallet to check the eligibility.</Typography>
  </Box>
}


const ConnectedInit = ({ onClickBack, onClickCheck }) => {
  const {isSmallHeight, isUltraSmallHeight} = useHeight()
  return <Box position={'relative'} height={'100%'} paddingX={4} display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <Header hideBack />
    <Box sx={{
      marginLeft: 6,
      marginTop: 5,
      height: isUltraSmallHeight ? 160 : isSmallHeight ? 200 : 260,
      width: isUltraSmallHeight ? 206 : isSmallHeight ? 258 : 335
    }} component={'img'} src={`${STATIC_RESOURCE}/air/drop.png`} />
    <Typography sx={{ marginTop: 3 }} color={textNormal} fontSize={isUltraSmallHeight ? '22px' : '28px'} textAlign={'center'}>Check your eligibility <br /> to claim <Typography fontSize={'28px'} component={'span'} color={taikoRed}>Taiko</Typography></Typography>
    {/* <Typography color={textLight} marginTop={1} fontSize={'11px'}>Claiming will be live until {moment(EXPIRE_TIMESTAMP).format('yyyy-MM-DD')}</Typography> */}
    <Typography color={textLight} marginTop={1} fontSize={'11px'}>The claim is only eligible between June 4, 2024, and July 4, 2024 (GMT+8). Please note that if you have not confirmed the binding address by the deadline, you will no longer be able to claim the airdrop.</Typography>
    <Button1 fullWidth sx={{ marginTop: isUltraSmallHeight ? 2 : 5 }} onClick={() => {
      onClickCheck()
    }}>Check</Button1>
    <Typography position={'absolute'} textAlign={'center'} bottom={isUltraSmallHeight ? 32 : 48} left={32} right={32} color={textLight} fontSize={'11px'}>Please connect the wallet to check the eligibility.</Typography>
  </Box>
}

const ConnectedChecking = () => {
  return <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
    <Loading />
  </Box>
}

const StyledBox = styled(Box)`
  
 

`
const ConnectedNotEligible = ({ onClickBack, onClickOk }) => {
  const {isSmallHeight} = useHeight()
  return <Box position={'relative'} height={'100%'} paddingX={4} >
    <Header onClickBack={onClickBack}  />
    <Box  display={'flex'} flexDirection={'column'}  alignItems={'center'}>
     
      <Typography color={red} marginTop={15} fontSize={'28px'} >Sorry</Typography>
      <Typography color={red}  fontSize={'16px'} >This wallet is not eligible for the airdrop.</Typography>
      <Typography color={red}  fontSize={'16px'} >Please try another wallet.</Typography>
      {/* <Typography color={red} fontSize={'28px'}>Please try another one.</Typography> */}
      {/* <MeetRequirement isMeet={false} marginTop={10} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement>
    <MeetRequirement isMeet={false} marginTop={1.5} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement>
    <MeetRequirement isMeet={false} marginTop={1.5} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement> */}
    {/* <StyledBox marginTop={'8%'} maxHeight={'45%'}  sx={{
      overflowY: 'scroll'
    }}>
      <Typography color={textLight} fontSize={'14px'} >TAIKO Airdrop Eligibility and Criteria</Typography>
      <Typography color={textLight} fontSize={'13px'} >To qualify for the TAIKO airdrop, recipients must meet the following requirements:</Typography>
      <Typography color={textLight} fontSize={'13px'} >Entry Criteria:</Typography>
      <Typography color={textLight} fontSize={'13px'} >To be eligible for the airdrop, the recipient's address must have engaged in at least one Loopring smart wallet transaction or one Loopring L2 transaction within the 12 months prior to the airdrop. The snapshot for this airdrop is set at the Ethereum mainnet block height 19859999. Multiplier Factor Criteria:</Typography>
      <Typography color={textLight} fontSize={'13px'} >1) Hold a minimum of 1,000 LRC on L1 or Loopring L2 </Typography>
      <Typography color={textLight} fontSize={'13px'} >2) Have a Loopring L2 account</Typography>
      <Typography color={textLight} fontSize={'13px'} >3) Use a Loopring smart wallet address</Typography>
      <Typography color={textLight} fontSize={'13px'} >4) Hold one or more Loopheads NFTs</Typography>
      <Typography color={textLight} fontSize={'13px'} >5) Hold one or more Taiko Task NFTs</Typography>
      <Typography color={textLight} fontSize={'13px'} >6) Hold more than 100,000 LRC on L1 or Loopring L2</Typography>
      <Typography color={textLight} fontSize={'14px'} >Weight Categories:</Typography>
      <Typography color={textLight} fontSize={'13px'} >Each additional criterion met (from the Multiplier Factor Criteria) will double the address's weight. The six different weight categories are: 1x, 2x, 4x, 8x, 16x, and 32x.</Typography>
      <Typography color={textLight} fontSize={'14px'} >Additional Note for Taiko Task NFT Holders:</Typography>
      <Typography color={textLight} fontSize={'13px'} >Since the Loopring smart wallet doesn't support deploying on various networks with the same address when the task kicked off, users have provided another Loopring Smart Wallet address to receive this NFT. That Loopring Smart Wallet address will be treated as eligible for at least the minimum 1x factor.
        This structured approach ensures that more engaged users receive greater rewards through the airdrop.</Typography>
      </StyledBox> */}
      <Box sx={{
        position: 'absolute',
        bottom: 48,
        left: 32,
        right: 32,
      }}>
        <Typography color={textNormal} fontSize={'13px'} marginBottom={2} display={'flex'} justifyContent={'center'}>
          Check criteria <Link marginLeft={0.5}  fontSize={'13px'} color={blue} sx={{ cursor: 'pointer' }} target="_blank" href={'https://loopring.io/#/document/TAIKO_Airdrop_Eligibility_and_Criteria.md'} >here</Link>
        </Typography>
        <Button1 fullWidth  onClick={onClickOk}>OK</Button1>

      </Box>
      
    </Box>
  </Box>
}
const ConnectedExpired = ({ onClickBack, onClickOk }) => {
  return <Box position={'relative'} height={'100%'} paddingX={4} >
    <Header  onClickBack={onClickBack}  />
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography marginTop={9}  color={orange} fontSize={'28px'} >Expired!</Typography>
      <Typography color={orange} fontSize={'17px'} >The airdrop claim time has expired</Typography>
      
      <Button1 sx={{ 
        position: 'absolute',
        bottom: 48,
        left: 32,
        right: 32,
      }} onClick={onClickOk}>OK</Button1>
      
      {/* <MeetRequirement marginTop={5.5} isMeet={true} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement>
    <MeetRequirement isMeet={true} marginTop={1.5} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement>
    <MeetRequirement isMeet={false} marginTop={1.5} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement> */}
    </Box>
  </Box>
}
const ConnectedClaimable = ({ amount,onClickBack, onClickClaim }) => {
  return <Box position={'relative'} height={'100%'} paddingX={4} >
    <Header  onClickBack={onClickBack}  />
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
      <Typography marginTop={15} color={textNormal} fontSize={'28px'} >Congratulations!</Typography>
      <Typography marginTop={2}  color={textNormal} fontSize={'16px'} >This wallet is eligible for the airdrop.</Typography>
      <Typography color={textNormal}  fontSize={'16px'} >Your total allocation is: {numberFormat(amount)} TAIKO</Typography>
      
      {/* <Box  marginTop={'8%'} maxHeight={'45%'}  sx={{
        overflowY: 'scroll'
      }}>
      <Typography color={textLight} fontSize={'14px'} >TAIKO Airdrop Eligibility and Criteria</Typography>
      <Typography color={textLight} fontSize={'13px'} >To qualify for the TAIKO airdrop, recipients must meet the following requirements:</Typography>
      <Typography color={textLight} fontSize={'13px'} >Entry Criteria:</Typography>
      <Typography color={textLight} fontSize={'13px'} >To be eligible for the airdrop, the recipient's address must have engaged in at least one Loopring smart wallet transaction or one Loopring L2 transaction within the 12 months prior to the airdrop. The snapshot for this airdrop is set at the Ethereum mainnet block height 19859999. Multiplier Factor Criteria:</Typography>
      <Typography color={textLight} fontSize={'13px'} >1) Hold a minimum of 1,000 LRC on L1 or Loopring L2 </Typography>
      <Typography color={textLight} fontSize={'13px'} >2) Have a Loopring L2 account</Typography>
      <Typography color={textLight} fontSize={'13px'} >3) Use a Loopring smart wallet address</Typography>
      <Typography color={textLight} fontSize={'13px'} >4) Hold one or more Loopheads NFTs</Typography>
      <Typography color={textLight} fontSize={'13px'} >5) Hold one or more Taiko Task NFTs</Typography>
      <Typography color={textLight} fontSize={'13px'} >6) Hold more than 100,000 LRC on L1 or Loopring L2</Typography>
      <Typography color={textLight} fontSize={'14px'} >Weight Categories:</Typography>
      <Typography color={textLight} fontSize={'13px'} >Each additional criterion met (from the Multiplier Factor Criteria) will double the address's weight. The six different weight categories are: 1x, 2x, 4x, 8x, 16x, and 32x.</Typography>
      <Typography color={textLight} fontSize={'14px'} >Additional Note for Taiko Task NFT Holders:</Typography>
      <Typography color={textLight} fontSize={'13px'} >Since the Loopring smart wallet doesn't support deploying on various networks with the same address when the task kicked off, users have provided another Loopring Smart Wallet address to receive this NFT. That Loopring Smart Wallet address will be treated as eligible for at least the minimum 1x factor.
        This structured approach ensures that more engaged users receive greater rewards through the airdrop.</Typography>
      </Box> */}
      

      {/* <Button1 sx={{ 
        position: 'absolute',
        bottom: 48,
        left: 32,
        right: 32,
      }} onClick={onClickClaim}>Claim</Button1> */}
      <Box sx={{
        position: 'absolute',
        bottom: 48,
        left: 32,
        right: 32,
      }}>
        <Typography color={textNormal} fontSize={'13px'} marginBottom={2} display={'flex'} justifyContent={'center'}>
          Check criteria <Link marginLeft={0.5}  fontSize={'13px'} color={blue} sx={{ cursor: 'pointer' }} target="_blank" href={'https://loopring.io/#/document/TAIKO_Airdrop_Eligibility_and_Criteria.md'} >here</Link>
        </Typography>
        <Button1 fullWidth onClick={onClickClaim}>Claim</Button1>

      </Box>
      
      {/* <MeetRequirement marginTop={5.5} isMeet={true} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement>
    <MeetRequirement isMeet={true} marginTop={1.5} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement>
    <MeetRequirement isMeet={false} marginTop={1.5} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement> */}
    </Box>
  </Box>
}
const ConnectedBound = ({ amount,onClickBack, onClickOk }) => {
  return <Box position={'relative'} height={'100%'} paddingX={4} >
    <Header onClickBack={onClickBack}  />
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography marginTop={15} fontSize={'40px'} color={orange}>{numberFormat(amount) } TAIKO</Typography>
      <Typography textAlign={'center'} color={textNormal} fontSize={'15px'} marginTop={2.5}>You have claimed the airdrop</Typography>
      <Typography textAlign={'center'} color={textNormal} fontSize={'15px'} >Waiting for Loopring to transfer the token.</Typography>
      <Button1 sx={{ 
        position: 'absolute',
        bottom: 48,
        left: 32,
        right: 32,
       }} onClick={onClickOk}>OK</Button1>
    </Box>
  </Box>
}

const ConnectedClaimed = ({ amount,onClickBack, onClickOk }) => {
  return <Box position={'relative'} height={'100%'} paddingX={4} >
    <Header onClickBack={onClickBack}  />
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography marginTop={15} fontSize={'40px'} color={green}>{numberFormat(amount) } TAIKO</Typography>
      <Typography color={textNormal} textAlign={'center'} fontSize={'15px'} marginTop={2.5}>You have claimed the airdrop</Typography>
      <Typography color={textNormal} textAlign={'center'} fontSize={'15px'}>Loopring has transferred the tokens to you.</Typography>
      <Button1 sx={{ 
        position: 'absolute',
        bottom: 48,
        left: 32,
        right: 32,
       }} onClick={onClickOk}>OK</Button1>
    </Box>
  </Box>
}

const ConnectedNetworkError = ({ onClickBack, onClickOk,errMsg }) => {
  return <Box position={'relative'} height={'100%'} paddingX={4} >
    <Header onClickBack={onClickBack}  />
    <Box  display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography color={red} textAlign={'center'} marginTop={9} fontSize={'28px'} >Network Error </Typography>
      <Typography color={red} textAlign={'center'} fontSize={'17px'} >{errMsg} </Typography>

      {/* <MeetRequirement isMeet={false} marginTop={10} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement>
    <MeetRequirement isMeet={false} marginTop={1.5} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement>
    <MeetRequirement isMeet={false} marginTop={1.5} text={'Meet the Taiko A1 to A6 in loopring all kinds of test conditions'}></MeetRequirement> */}
      <Button1 sx={{
        position: 'absolute',
        bottom: 48,
        left: 32,
        right: 32,
      }} onClick={onClickOk}>OK</Button1>
    </Box>
  </Box>
}

export default () => {
  const { open } = useWeb3Modal()
  const { isConnected, address } = useWeb3ModalAccount()

  const [state, setState] = useState({
    checkStatus: 'init',
    addressCheckResult: undefined
  })
  const status = isConnected
    ? (
      new Map([
        ['init', 'connectedInit'],
        ['checking', 'connectedChecking'],
        ['networkError', 'connectedNetworkError'],
        ['notEligible', 'connectedNotEligible'],
        ['claimable', 'connectedClaimable'],
        ['claimed', 'connectedClaimed'],
        ['bound', 'connectedBound'],
        ['expired', 'connectedExpired'],
      ]).get(state.checkStatus)
    )
    : 'unConnected'
  useEffect(() => {
    setState({
      checkStatus: 'init',
      // amount: '111'
    })
  }, [isConnected])
  const nav=useNavigate()

  return <Box height={'100%'}>
    {
      status === 'unConnected' ? <UnConnected onClickConnect={() => open()} />
        : status === 'connectedInit' ? <ConnectedInit

          onClickCheck={() => {
            setState({
              checkStatus: 'checking'
            })
            if (true) {
              setState({
                checkStatus: 'expired'
              })
              return
            }
            check(address).then(result => {
              if (result.isClaimable) {
                if (result.isClaimed) {
                  setState({
                    checkStatus: 'claimed',
                    amount: result.amount
                  })
                } else if (result.addressBound){
                  setState({
                    checkStatus: 'bound',
                    amount: result.amount
                  })
                }else {
                  setState({
                    checkStatus: 'claimable',
                    amount: result.amount
                  })
                }
              } else {
                setState({
                  checkStatus: 'notEligible',
                })
              }
              setState(state => ({
                ...state,
                addressCheckResult: result
              }))

            }).catch((err) => {
              if (err.message) {
                setState({
                  checkStatus: 'networkError',
                  errMsg: err.message,
                })
              } else {
                setState( {
                  checkStatus: 'init',
                })
              }
            })

          }} />
          : status === 'connectedChecking' ? <ConnectedChecking />
          : status === 'connectedNetworkError' ? <ConnectedNetworkError 
          errMsg={state.errMsg}
          onClickBack={() => {
            setState({
              checkStatus: 'init',
            })
          }}
          onClickOk={() => {
            setState({
              checkStatus: 'init',
            })

          }}
          />
            : status === 'connectedNotEligible' ? <ConnectedNotEligible
              onClickBack={() => {
                setState({
                  checkStatus: 'init',
                })
              }}
              onClickOk={() => {
                setState({
                  checkStatus: 'init',
                })

              }} />
              : status === 'connectedExpired' ? <ConnectedExpired 
              onClickBack={() => {
                setState({
                  checkStatus: 'init',
                })
              }}
              onClickOk={() => {
                setState({
                  checkStatus: 'init',
                })
              }}
              />
              : status === 'connectedClaimable' ? <ConnectedClaimable
                amount={state.amount}
                onClickBack={() => {
                  setState({
                    checkStatus: 'init',
                  })
                }}
                onClickClaim={() => {
                  nav('/claim', {state: state.addressCheckResult})
                  // alert('click claim')
                }} />
                : status === 'connectedBound' ? <ConnectedBound
                amount={state.amount}
                onClickBack={() => {
                  setState({
                    checkStatus: 'init',
                  })
                }}
                onClickOk={() => {
                  setState({
                    checkStatus: 'init',
                  })
                }}
                />
                : status === 'connectedClaimed' ? <ConnectedClaimed
                  amount={state.amount}
                  onClickBack={() => {
                    setState({
                      checkStatus: 'init',
                    })
                  }}
                  onClickOk={() => {
                    setState({
                      checkStatus: 'init',
                    })
                  }} />
                  : <>error</>
    }
  </Box>
}